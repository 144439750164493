.home {
    background: #DFE7FB;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.home-div {
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.home-div-inner {
    width: 100%;
    max-width: 1024px;
    margin: 80px auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
}

.home-div-inner h2 {
    font-size: 3em;
    font-weight: 500;
    color: #3D4556;
    margin: 0;
}

.home-div-inner h1 {
    font-size: 3.5em;
    font-weight: 500;
    color: #375BD3;
    margin-top: 10px;
    margin-bottom: 20px;
}

.left-top h4 {
    font-size: 2em;
    font-weight: 500;
    color: #3D4556;
    margin-top: 10px;
    margin-bottom: 60px;
    padding-bottom: 50px;
    border-bottom: 3px solid #3D4556;
}

.left-bottom p {
    font-size: 1.5em;
    color: #3D4556;
    line-height: 1.4em;
    margin-bottom: 0;
}

.right-side {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 975px) {

    .home, .home-div {
        background: none;
    }

    .home-div-inner {
        display: block;
    }

    .left-bottom {
        margin-bottom: 50px;
    }

    .right-side {
        display: block;
        text-align: left;
    }
}

@media (max-width: 660px) {
    .home-div-inner h2 {
        font-size: 2.5em;
    }
    
    .home-div-inner h1 {
        font-size: 3em;
    }
    
    .left-top h4 {
        font-size: 1.5em;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
    
    .left-bottom p {
        font-size: 1.3em;
    }
}

@media (max-width: 535px) {
    .left-bottom p br {
        display: none;
    }
}

@media (max-width: 503px) {

    .home-div-inner h2 {
        font-size: 2em;
    }

    .home-div-inner h1 {
        font-size: 2em;
    }

    .left-top h4 {
        font-size: 1.2em;
    }

    .left-bottom p {
        font-size: 1.1em;
    }
}

@media (max-width: 400px) {
    .home {
        padding-top: 20px;
    }

    .home-div-inner {
        margin: 0;
    }
}