.contact {
    background: #375BD3;
    color: #ffffff;
    display: flex;
}

.contact-div {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 15px 50px 15px;
    width: 100%;
    max-width: 1024px;
}

.contact-div .contactIcons {
    margin-left: 30px;
    color: #ffffff;
}

.contact h1 {
    font-size: 3.5em;
    font-weight: 500;
    margin: 30px 0 60px 0;
}

.contact p {
    font-size: 1.5em;
    line-height: 1.4em;
    margin-bottom: 0;
}

.left .contactIcons {
    margin-left: 0;
    margin-right: 30px;
}

.right {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right p, #email {
    text-shadow: 10px 17px 17px rgba(12,22,44,.32);
    transition: .1s;
}

.right p:hover, #email:hover {
    color: #DFE7FB;
    cursor: pointer;
    text-shadow: 10px 7px 7px rgba(12,22,44,.32);
}

#email {
    margin-top: 50px;
}

@media (min-width: 851px) {
    .right .iconsMobile {
        display: none;
    }
}

@media (max-width: 800px) {
    .contact h1 {
        font-size: 2.5em;
    }
}

@media (max-width: 850px) {
    .contact-div {
        display: block;
    }

    .right {
        text-align: left;
    }

    .right .contactIcons {
        display: none;
    }

    .right .iconsMobile {
        margin-right: 35px;
    }

    #gitIcon {
        margin-right: 33px;
    }
}

@media (max-width: 515px) {
    .contact p {
        font-size: 1.2em;
    }
}

@media (max-width: 420px) {
    .contact br {
        display: none;
    }

    .left .contactIcons, .iconsMobile {
        margin-right: 25px;
    }
}