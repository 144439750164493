.projects {
    background: #F5F7FD;
    height: 100%;
    min-height: 100vh;
    display: flex;
}

.projects-div {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1024px;
}

.projects h1 {
    color: #1A2B6B;
    font-size: 3.5em;
    font-weight: 500;
    margin: 60px 0;
}

.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;
}

@media (max-width: 975px) {
    
    .projects {
        background: #DFE7FB;
    }
}

@media (max-width: 800px) {
    .projects h1 {
        font-size: 2.5em;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 756px) {
    
    .grid div {
        margin-bottom: 30px;
    }
}
